:root {
  --font-family: sans-serif;
  --font-weight-bold: 600;
  --font-weight-medium: 500;
  --font-weight-light: 400;
}

html {
  background-color: #333;
  color: #03DAC5; 
  font-family: var(--font-family);
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  text-align: left;
  margin: 1% 10% 2.5% 10%;
}

.underConstruction {
  width: 50%;
  border-radius: 30px;
}

.Parent1 {
  display: flex;
  flex-direction: row;
  margin: 0% 10% 5% 10%;
}

.Parent2 {
  display: flex;
  flex-direction: row;
  margin: 0% 5% 5% 5%;
}

.child1 {
  width: 70%;
  text-align: center;
  color: white;
}

.child1 img {
  width: 75%;
}

.child2 {
  width: 25%;
}

.child3 {
  width: 50%;
}

.child3 img {
  width: 75%;
}

.child4 {
  width: 20%;
}

.child5 {
  width: 30%;
}

.child2 h3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.child2 iframe {
  width: 100%;
  height: 100%;
  /* width: 400px ; */
  /* height: 315px; */
}

li {
  list-style-type: none;
}

.Ranking {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 5px 5px 5px 5px;
  justify-items: center;
}

.Ranking h2 {
  text-align: center;
}